import React from "react"

const SinglePress = ({ title, content, author, date }) => {
  return (
    <p>
      <i>{date}</i>
      <br />
      <strong>{title}</strong>
      <br />
      {content}
      <br />
      <br />
      {author}
    </p>
  )
}

export default SinglePress
