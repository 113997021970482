import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Image from "gatsby-image"
import { FaShoppingCart } from "react-icons/fa"

const getRecordingData = graphql`
  query {
    recordings: allContentfulRecordings(
      sort: { fields: release, order: DESC }
    ) {
      totalCount
      edges {
        node {
          title
          release
          articelNumber
          link
          amazonLink
          content {
            content
          }
          id: contentful_id
          cover {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const MultiCarousel = () => {
  const data = useStaticQuery(getRecordingData)
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }
  return (
    <Carousel
      swipeable={true}
      draggable={false}
      showDots={false}
      responsive={responsive}
      ssr={false} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={5000}
      keyBoardControl={true}
      customTransition="all .5"
      transitionDuration={500}
      containerClass="carousel-container"
      removeArrowOnDeviceType={["tablet", "mobile"]}
      // deviceType={this.props.deviceType}
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
      renderDotsOutside="false"
    >
      {data.recordings.edges.map(({ node }) => (
        <SlideItem key={node.id} className="card mx-3 mb-3">
          <Image
            className="card-img-top"
            fluid={node.cover.fluid}
            alt="CD-Cover"
          />
          <div className="card-body">
            <h6 className="card-title">{node.title}</h6>
            <p className="card-text">{node.content.content}</p>
            <p className="card-text">
              <a href={node.link} target="_blank" rel="noopener noreferrer">
                {node.articelNumber}
              </a>
            </p>
          </div>
          <div className="card-footer text-center">
            <a target="_blank" rel="noopener noreferrer" href={node.amazonLink}>
              <FaShoppingCart />
            </a>
          </div>
        </SlideItem>
      ))}
    </Carousel>
  )
}

const SlideItem = styled.div`
  min-height: 800px;
  box-shadow: var(--lightShadow);
  transition: var(--mainTransition);
  border-radius: 10px 10px;
  &:hover {
    box-shadow: var(--darkShadow);
  }
  button {
    outline: none !important;
  }

  a:hover {
    transition: var(--mainTransition);
    color: var(--mainGrey);
  }
`

export default MultiCarousel
