import React from "react"
import Title from "../Title"
import { Container } from "reactstrap"
import styled from "styled-components"
import StyledHero from "../StyledHero"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

const getContactData = graphql`
  query {
    contactjun: file(relativePath: { eq: "jun-contact.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    banner: file(relativePath: { eq: "multi-banner.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
const Contact = () => {
  const data = useStaticQuery(getContactData)
  return (
    <ContactWrapper>
      <StyledHero img={data.banner.childImageSharp.fluid} />
      <section className="py-5">
        <Container>
          <Title title="Contact" />
          <hr />
          <div className="d-md-flex align-items-center content-box">
            <div className="col-lg-4 order-sm-3 my-3 my-md-0">
              <Image
                fluid={data.contactjun.childImageSharp.fluid}
                alt="jun-contact"
              />
            </div>
            <div className="col-md-4 order-sm-1 mt-5 mt-md-0">
              <h6>
                <strong>Worldwide</strong>
              </h6>
              <p>
                Ron Merlino
                <br />
                MusicVine
                <br />
                Performing Arts and Wine Consulting
                <br />
                2576 Broadway #239
                <br />
                New York, NY 10025
                <br />
                Tel: +001 (646) 825-9585
                <br />
                Cell: +001 (917) 544-9590
                <br />
                Email:{" "}
                <a href="mailto:musicvine@gmail.com">musicvine@gmail.com</a>
                <br />
                Website:{" "}
                <a
                  href="https://www.musicvinearts.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.musicvinearts.com
                </a>
              </p>
            </div>
            <div className="col-md-4 order-sm-2 mt-5 mt-md-0">
              <h6>
                <strong>Japan</strong>
              </h6>
              <p>
                Shohei Abe (Mr.)
                <br />
                Executive Vice President
                <br />
                AMATI Inc.
                <br />
                S201, 1-14-5 Akasaka, Minato-ku
                <br />
                Tokyo, JAPAN 107-0052
                <br />
                TEL:+81-(0)3-3560-3007
                <br />
                FAX:+81-(0)3-3560-3008
                <br />
                Email:{" "}
                <a href="mailto:shohei.abe@amati-tokyo.com">
                  shohei.abe@amati-tokyo.com
                </a>
                <br />
                Website:{" "}
                <a
                  href="https://www.amati-tokyo.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.amati-tokyo.com
                </a>
              </p>
            </div>
          </div>
        </Container>
      </section>
    </ContactWrapper>
  )
}

const ContactWrapper = styled.div`
  a {
    color: var(--primaryColor);
    word-break: break-word;
  }
  img {
    border-radius: 20px;
  }
`

export default Contact
