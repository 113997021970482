import React from "react"
import { MdDateRange, MdLocationOn } from "react-icons/md"
import Modal from "../ModalField"
import styled from "styled-components"

const SinglePerformance = ({ title, date, location, content }) => {
  return (
    <PerformancWrapper>
      <article className="tour mt-3">
        <div className="card-header">
          <h5>{title}</h5>
        </div>
        <div className="container">
          <div className="row">
            <div className="info-box col-md-4 m-auto">
              <div className="row">
                <div className="col-2 m-auto">
                  <MdDateRange
                    style={{ color: "#343a40", fontSize: "1.5em" }}
                  />
                </div>
                <div className="col-10 m-auto">
                  <h6>{date}</h6>
                </div>
              </div>
            </div>
            <div className="info-box col-md-5 m-auto">
              <div className="row">
                <div className="col-2 m-auto">
                  <MdLocationOn
                    style={{ color: "#343a40", fontSize: "1.5em" }}
                  />
                </div>
                <div className="col-10 m-auto">
                  <h6>{location}</h6>
                </div>
              </div>
            </div>
            <div className="info-box col-md-3 text-center text-md-right">
              <Modal title={title} content={content} buttonLabel="Show more" />
            </div>
          </div>
        </div>
      </article>
    </PerformancWrapper>
  )
}

const PerformancWrapper = styled.div`
  .info-box {
    padding: 13px;
    h6 {
      margin-bottom: 0;
    }
    span {
      text-transform: uppercase;
    }
  }
  @media (min-width: 768px) {
    .info-box {
      padding: 30px;
    }
  }
`

export default SinglePerformance
