import React from "react"
import { Link } from "gatsby"

import StyledHero from "../StyledHero"
import Banner from "../Banner"
import ButtonPrimary from "../ButtonPrimary"

const Intro = () => {
  return (
    <StyledHero home="true">
      <Banner
        title="Jun Märkl"
        info="Jun Märkl conducts the world's leading orchestras, such as the Cleveland Orchestra, Philadelphia Orchestra, NHK Symphony Orchestra, Czech Philharmonic, Munich Philharmonic, Oslo Philarmonic and Tonhalle Orchester Zürich. He has long been a highly respected interpreter of the core Germanic repertoire from both the symphonic and operatic traditions, and more recently for his refined and idiomatic Debussy, Ravel and Messiaen."
      >
        <Link to="/#news">
          <ButtonPrimary title="click for more" />
        </Link>
      </Banner>
    </StyledHero>
  )
}

export default Intro
