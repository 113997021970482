import React from "react"
import { Container } from "reactstrap"
import styled from "styled-components"

import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Title from "../Title"
import SinglePress from "./SinglePress"
import CollapseComponent from "../CollapseComponent"

import StyledHero from "../StyledHero"
import { richTextOptions } from "../../utils/richTextRender";

const getPressData = graphql`
  query {
    allContentfulPress(sort: { fields: date, order: DESC }) {
      nodes {
        richContent {
          json
        }
      }
      edges {
        node {
          title
          content {
            content
          }
          id: contentful_id
          date(formatString: "DD. MMM YYYY")
          author
        }
      }
    }
    banner: file(relativePath: { eq: "press-banner.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Press = () => {
  const data = useStaticQuery(getPressData)
  const { date, title, author } = data.allContentfulPress.edges[0].node
  const { richContent } = data.allContentfulPress.nodes[0]
  return (
    <PressWrapper>
      <StyledHero img={data.banner.childImageSharp.fluid} />
      <section className="py-5">
        <Container>
          <Title title="Press" />
          <hr />
          <div className="content-box pb-2">
            <div className="">
              <i>{date}</i>
              <br />
              <strong>{title}</strong>
            </div>
            <div>
              <br />
              {documentToReactComponents(richContent.json, richTextOptions)}
              <br />
              <br />
              {author && `Author: ${author}`}
            </div>
            <CollapseComponent
              content={data.allContentfulPress.edges
                .slice(1)
                .map(({ node }) => {
                  return (
                    <SinglePress
                      key={node.id}
                      title={node.title}
                      content={node.content.content}
                      date={node.date}
                      author={node.author}
                    />
                  )
                })}
            />
          </div>
        </Container>
      </section>
    </PressWrapper>
  )
}

const PressWrapper = styled.div``

export default Press
