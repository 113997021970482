import React from "react"

const SingleNews = ({
  date,
  title,
  content,
  image,
  width,
  height,
  linkTitle,
  link,
  secondLinkTitle,
  secondLink,
}) => {
  return (
    <div className="row">
      <div className="date-border font-weight-bold col-md-2 pb-2">{date}</div>

      <div className="col-md-10 mt-3 mt-md-0">
        <h5>{title}</h5>
        {image && (
          <div className="py-2">
            <img
              src={image.fluid.src}
              height={height}
              width={width}
              alt={title}
            />
          </div>
        )}
        <p>{content}</p>
        {linkTitle && link && (
          <div>
            <a href={link} target="_blank" rel="noopener noreferrer">
              {linkTitle}
            </a>
          </div>
        )}
        {secondLinkTitle && secondLink && (
          <div>
            <a href={secondLink} target="_blank" rel="noopener noreferrer">
              {secondLinkTitle}
            </a>
          </div>
        )}
      </div>
      <hr />
    </div>
  )
}

export default SingleNews
