import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Title from "../Title"
import { Container } from "reactstrap"
import styled from "styled-components"
import StyledHero from "../StyledHero"

const getMultimediaData = graphql`
  query {
    banner: file(relativePath: { eq: "contact-banner.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Multimedia = () => {
  const data = useStaticQuery(getMultimediaData)
  return (
    <MultiWrapper>
      <StyledHero img={data.banner.childImageSharp.fluid} />
      <section className="py-5" style={{ background: "var(--bgGrey)" }}>
        <Container>
          <Title title="Multimedia" />
          <hr />
          <div className="d-flex justify-content-between flex-wrap">
            <div className="embed-responsive embed-responsive-16by9 z-depth-1-half mb-5 mb-md-0">
              <iframe
                id="guest-video"
                title="guest"
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/pYgm_zU1qzQ?enablejsapi=1&html5=1"
                srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/pYgm_zU1qzQ?autoplay=1><img src=https://img.youtube.com/vi/pYgm_zU1qzQ/hqdefault.jpg alt=''><span>▶</span></a>"
                allowscriptaccess="always"
                allowFullScreen
              ></iframe>
            </div>
            <div className="embed-responsive embed-responsive-16by9 z-depth-1-half mb-5 mb-md-0">
              <iframe
                id="space-video"
                title="space"
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/BekjyAH7c2A?enablejsapi=1&html5=1"
                srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/BekjyAH7c2A?autoplay=1><img src=https://img.youtube.com/vi/BekjyAH7c2A/hqdefault.jpg alt=''><span>▶</span></a>"
                allowscriptaccess="always"
                allowFullScreen
              ></iframe>
            </div>
            <div className="embed-responsive embed-responsive-16by9 z-depth-1-half mb-5 mb-md-0">
              <iframe
                id="orchestre-video"
                title="orchestre"
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/StgXSRLyzNk?enablejsapi=1&html5=1"
                srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/StgXSRLyzNk?autoplay=1><img src=https://img.youtube.com/vi/StgXSRLyzNk/hqdefault.jpg alt=''><span>▶</span></a>"
                allowscriptaccess="always"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </Container>
      </section>
    </MultiWrapper>
  )
}

const MultiWrapper = styled.div`
  .embed-responsive {
    width: 100%;
  }
  @media (min-width: 768px) {
    .embed-responsive {
      width: 30%;
    }
  }
`

export default Multimedia
