import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import { Row, Col } from "reactstrap"
import Gallery from "@browniebroke/gatsby-image-gallery"

import ButtonPrimary from "../components/ButtonPrimary"

const getData = graphql`
  query {
    allPhotos: allContentfulPhotos {
      edges {
        node {
          images {
            full: fluid {
              ...GatsbyContentfulFluid_withWebp
            }
            thumb: fluid(maxWidth: 1000, maxHeight: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
            title: title
            thumbAlt: title
          }
        }
      }
    }
    professionalPhotos: allContentfulPhotos(filter: { type: { eq: "prof" } }) {
      edges {
        node {
          images {
            full: fluid {
              ...GatsbyContentfulFluid_withWebp
            }
            thumb: fluid(maxWidth: 1000, maxHeight: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
            title: title
            thumbAlt: title
          }
        }
      }
    }
    work: allContentfulPhotos(filter: { type: { eq: "work" } }) {
      edges {
        node {
          images {
            full: fluid {
              ...GatsbyContentfulFluid_withWebp
            }
            thumb: fluid(maxWidth: 1000, maxHeight: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
            title: title
            thumbAlt: title
          }
        }
      }
    }
    privat: allContentfulPhotos(filter: { type: { eq: "privat" } }) {
      edges {
        node {
          images {
            full: fluid {
              ...GatsbyContentfulFluid_withWebp
            }
            thumb: fluid(maxWidth: 1000, maxHeight: 1000) {
              ...GatsbyContentfulFluid_withWebp
            }
            title: title
            thumbAlt: title
          }
        }
      }
    }
  }
`

const GalleryImages = () => {
  const data = useStaticQuery(getData)
  // const data = useStaticQuery(getData)
  // const images = data.photos.edges.map(({ node }) => node.images)
  // `images` is an array of objects with `thumb` and `full`
  const allImages = data.allPhotos.edges.map(({ node }) => node.images)
  const professionalImages = data.professionalPhotos.edges.map(
    ({ node }) => node.images
  )
  const work = data.work.edges.map(({ node }) => node.images)
  const privat = data.privat.edges.map(({ node }) => node.images)
  const [images, setImages] = useState(allImages.slice(0, 8))

  return (
    <>
      <Row className="mb-3">
        <Col md={2} className="text-center">
          <ButtonPrimary
            title="All"
            inverted
            onClick={() => setImages(allImages)}
            className="w-100 junbutton mb-3"
          />
        </Col>
        <Col md={4} className="text-center">
          <ButtonPrimary
            title="For proffessional use"
            inverted
            onClick={() => setImages(professionalImages)}
            className="w-100 junbutton mb-3"
          />
        </Col>
        <Col md={4} className="text-center">
          <ButtonPrimary
            title="work"
            inverted
            onClick={() => setImages(work)}
            className="w-100 junbutton mb-3"
          />
        </Col>
        <Col md={2} className="text-center">
          <ButtonPrimary
            title="privat"
            inverted
            onClick={() => setImages(privat)}
            className="w-100 junbutton mb-3"
          />
        </Col>
      </Row>
      <Gallery images={images} />
    </>
  )
}

export default GalleryImages
