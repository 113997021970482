import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Container } from "reactstrap"

import Title from "../Title"
import styled from "styled-components"
import StyledHero from "../StyledHero"
import MultiCarousel from "./MultiCarousel"
import ButtonPrimary from "../ButtonPrimary"

const getRecordingsData = graphql`
  query {
    banner: file(relativePath: { eq: "jun-record.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Recordings = () => {
  const data = useStaticQuery(getRecordingsData)
  return (
    <RecordingsWrapper>
      <StyledHero img={data.banner.childImageSharp.fluid} />
      <section className="py-5" style={{ background: "var(--bgGrey)" }}>
        <Container>
          <Title title="Recordings" />
          <hr />
          <div className="text-center mb-5">
            <AniLink fade to="/recordings">
              <ButtonPrimary
                className="btn"
                title="Recordings overview"
                inverted
              />
            </AniLink>
          </div>
          <MultiCarousel />
        </Container>
      </section>
    </RecordingsWrapper>
  )
}

const RecordingsWrapper = styled.div``

export default Recordings
