import React from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import styled from "styled-components"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

class ModalField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  render() {
    return (
      <ModalWrapper>
        <Button className="junbutton" onClick={this.toggle}>
          {this.props.buttonLabel}
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>{this.props.title}</ModalHeader>
          <ModalBody>
            {this.props.program}
            {documentToReactComponents(this.props.content.json)}
          </ModalBody>
          <ModalFooter>
            <Button className="junbutton" onClick={this.toggle}>
              Back
            </Button>
          </ModalFooter>
        </Modal>
      </ModalWrapper>
    )
  }
}

const ModalWrapper = styled.div`
  .modal {
    display: flex !important;
  }
  .modal-dialog {
    margin: 1.75rem auto;
    display: flex !important;
    align-items: center !important;
  }
`

export default ModalField
