import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Title from "../Title"
import { Container } from "reactstrap"

import StyledHero from "../StyledHero"
import GalleryImages from "../GalleryImages"

const getPhotosData = graphql`
  query {
    banner: file(relativePath: { eq: "photo-banner.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Photos = () => {
  const data = useStaticQuery(getPhotosData)
  return (
    <>
      <StyledHero img={data.banner.childImageSharp.fluid} />
      <section className="py-5">
        <Container>
          <Title title="Photos" />
          <hr />
          <GalleryImages />
        </Container>
      </section>
    </>
  )
}

export default Photos
