import React from "react"
import styled from "styled-components"

const SectionDivider = ({ id, news }) => (
  <StyledSectionDivider
    id={id}
    className="section-divider"
    news={news}
  ></StyledSectionDivider>
)

const StyledSectionDivider = styled.div`
  height: ${props => (props.news ? "0px" : "60px")};
  margin-top: ${props => (props.news ? "-60px" : "0px")};
  padding-top: ${props => (props.news ? "60px" : "0px")};
  background: var(--mainGrey);
`

export default SectionDivider
