import React from "react"
import SinglePerformance from "./SinglePerformance"
import { graphql, useStaticQuery } from "gatsby"

const getPerformanceData = graphql`
  query {
    allContentfulPerformances(sort: { fields: when, order: ASC }) {
      edges {
        node {
          createdAt
          title
          date
          location
          id: contentful_id
          content {
            json
          }
        }
      }
    }
  }
`

const PerformanceList = ({ filteredDate }) => {
  const data = useStaticQuery(getPerformanceData)
  const monthDate = filteredDate.toString().slice(4, 7)
  const yearDate = filteredDate.toString().slice(11, 15)
  const filteredPerformenceContent = data.allContentfulPerformances.edges.filter(
    item =>
      item.node.date.match(new RegExp(`(?=.*${monthDate}).(?=.*${yearDate})`))
  )
  if (filteredPerformenceContent.length === 0 || !filteredPerformenceContent) {
    return <div className="text-center mt-5">No concert entries</div>
  }
  return (
    <div>
      {filteredPerformenceContent.map(({ node }) => {
        return (
          <SinglePerformance
            key={node.id}
            title={node.title}
            date={node.date}
            location={node.location}
            content={node.content}
          />
        )
      })}
    </div>
  )
}

export default PerformanceList
