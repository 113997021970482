import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"

const HeadingOne = ({ children }) => <h1 className="mt-5 mb-4">{children}</h1>
const HeadingTwo = ({ children }) => <h2 className="mt-5 mb-4">{children}</h2>
const HeadingFive = ({ children }) => <h5 className="mt-5 mb-4">{children}</h5>
const UnorderedList = ({ children }) => <li style={{ listStyle: 'none'}}><strong>{children}</strong></li>
const Pharagraph = ({ children }) => <p>{children}</p>

export const richTextOptions = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <HeadingOne>{children}</HeadingOne>,
    [BLOCKS.HEADING_2]: (node, children) => <HeadingTwo>{children}</HeadingTwo>,
    [BLOCKS.HEADING_5]: (node, children) => <HeadingFive>{children}</HeadingFive>,
    [BLOCKS.UL_LIST]: (node, children) => <UnorderedList>{children}</UnorderedList>,
    [BLOCKS.PARAGRAPH]: (node, children) => <Pharagraph>{children}</Pharagraph>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { title, description, file } = node.data.target.fields;
      const mimeType = file['en-US'].contentType;
      const imageUrl = file['en-US'].url;

      switch (mimeType) {
        case 'image/jpeg':
        case 'image/png':
          return (
            <img
              src={imageUrl}
              alt={title ? title['en-US'] : 'Image'}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          );
        case 'video/mp4':
          return (
            <video controls>
              <source src={imageUrl} type={mimeType} />
            </video>
          );
        // Add more cases as needed for different types of assets
        default:
          return <p>Unsupported asset type</p>;
      }
    },
  },
};