import React from "react"
import { Parallax, Background } from "react-parallax"

const ParallaxJun = ({ src, alt, strength }) => {
  return (
    <Parallax strength={strength}>
      <div style={{ height: "500px" }}></div>
      <Background className="custom-bg">
        <img src={src} alt={alt} />
      </Background>
    </Parallax>
  )
}

export default ParallaxJun
