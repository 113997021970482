import React, { useState, forwardRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Title from "../Title"
import { Container } from "reactstrap"
import PerformanceList from "./PerformanceList"
import StyledHero from "../StyledHero"
import styled from "styled-components"
import DatePicker from "react-datepicker"
import "../Performances/scss/datepicker.scss"
import { FaFilter } from "react-icons/fa"

const getPerformanceData = graphql`
  query {
    banner: file(relativePath: { eq: "performance-banner.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Performances = () => {
  const data = useStaticQuery(getPerformanceData)
  const [startDate, setStartDate] = useState(new Date())
  const DatePickerButton = forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} className="junbutton btn mb-2" onClick={onClick}>
      {value}
    </button>
  ))
  const [hidden, setHidden] = useState()
  const toggleHidden = () => {
    setHidden(hidden => !hidden)
    setStartDate(date => (date ? "" : new Date()))
  }

  return (
    <PerformanceWrapper>
      <StyledHero img={data.banner.childImageSharp.fluid} />
      <section className="py-5">
        <Container>
          <Title title="Performances" />
          <hr />
          <div className="row">
            <div className="col-12 text-center">
              <FaFilter className="mb-1" />
              {!hidden && (
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  customInput={<DatePickerButton />}
                  showMonthYearPicker
                  dateFormat="MMMM, yyyy"
                  withPortal="false"
                />
              )}
              <div className="col-12">
                <button className="junbutton btn" onClick={toggleHidden}>
                  {!hidden ? "Show all" : "Use filter"}
                </button>
              </div>
            </div>
          </div>
          <PerformanceList filteredDate={startDate} />
        </Container>
      </section>
    </PerformanceWrapper>
  )
}

const PerformanceWrapper = styled.div`
  .tour {
    box-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
    transition: all 0.3s linear;
    border-radius: 10px 10px;
    &:hover {
      box-shadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
    }

    .card-header {
      background: #343a40;
      transition: all 0.3s linear;
      color: white;
      border-bottom: 5px solid #ff8a00;
      border-radius: 10px 10px 0 0;
    }
  }
`

export default Performances
