import React, { Component } from "react"
import { Collapse, Button } from "reactstrap"

class CollapseComponent extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = { collapse: false }
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }))
  }

  render() {
    return (
      <div>
        <Button
          className="junbutton"
          onClick={this.toggle}
          style={{ marginBottom: "1rem" }}
        >
          {this.state.collapse ? "Show less" : "Show more"}
        </Button>
        <Collapse isOpen={this.state.collapse}>{this.props.content}</Collapse>
      </div>
    )
  }
}

export default CollapseComponent
