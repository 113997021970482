import React from "react"

import { graphql } from "gatsby"

import style from "../components/mobile-navi-style"
import "react-bnb-gallery/dist/style.css"

import "../utils/smoothScroll"
import "../utils/resizer"

import Layout from "../components/layout"

import SectionDivider from "../components/SectionDivider"
import SEO from "../components/seo"
import BurgerMenu from "../components/MobileNavigation"

import Intro from "../components/Intro/Intro"
import News from "../components/News/News"
import Performance from "../components/Performances/Performance"
import Recordings from "../components/Recordings/Recordings"
import Press from "../components/Press/Press"
import Biography from "../components/Biography/Biography"
import Photos from "../components/Photos/Photos"
import Multimedia from "../components/Multimedia/Multimedia"
import Contact from "../components/Contact/Contact"
import ParallaxJun from "../components/Parallax"

export default ({ data }) => {
  const parallaxOneSrc = data.paraOne.childImageSharp.fluid.src
  const parallaxTwoSrc = data.paraTwo.childImageSharp.fluid.src
  const parallaxThreeSrc = data.paraThree.childImageSharp.fluid.src
  const MenuWrapper = style
  return (
    <>
      <MenuWrapper className="d-lg-none">
        <BurgerMenu />
      </MenuWrapper>
      <SEO
        title="Home"
        keywords={[
          `Jun Märkl`,
          `Jun Märkel`,
          `Jun Markl`,
          `Jun Markel`,
          `Jun Maerkl`,
          `Jun Maerkel`,
        ]}
      />
      <div id="home"></div>
      <Intro />
      <Layout>
        <SectionDivider id="news" news />
        <News />
        <ParallaxJun
          strength={300}
          src={parallaxOneSrc}
          alt="Jun Märkl looks at the sky"
        />
        <SectionDivider id="performances" height={60} />
        <Performance />
        <SectionDivider id="recordings" height={60} />
        <Recordings />
        <ParallaxJun
          strength={600}
          src={parallaxThreeSrc}
          alt="Jun Märkl looks at you"
        />
        <SectionDivider id="press" height={60} />
        <Press />
        <SectionDivider id="biography" height={60} />
        <Biography />
        <SectionDivider id="photos" height={60} />
        <Photos />
        <ParallaxJun strength={300} src={parallaxTwoSrc} alt="Jun Märkl lean" />
        <SectionDivider id="multimedia" height={60} />
        <Multimedia />
        <SectionDivider id="contact" height={60} />
        <Contact />
      </Layout>
    </>
  )
}

export const data = graphql`
  query {
    default1: file(relativePath: { eq: "jun-header.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    paraOne: file(relativePath: { eq: "parallax-one.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    paraTwo: file(relativePath: { eq: "parallax-two.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    paraThree: file(relativePath: { eq: "parallax-three.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
