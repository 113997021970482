import React from "react"
import Title from "../Title"
import { Container } from "reactstrap"
import styled from "styled-components"
import StyledHero from "../StyledHero"
import { graphql, useStaticQuery } from "gatsby"
import SingleNews from "./SingleNews"

const getNewsData = graphql`
  query {
    news: allContentfulNews(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id: contentful_id
          date(formatString: "DD. MMM YYYY")
          title
          createdAt(formatString: "DD. MMM YYYY")
          content {
            content
          }
          image {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          width
          height
          linkTitle
          link
          secondLinkTitle
          secondLink
        }
      }
    }
    banner: file(relativePath: { eq: "news-banner.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const News = () => {
  const data = useStaticQuery(getNewsData)
  return (
    <NewsWrapper>
      <StyledHero img={data.banner.childImageSharp.fluid} />
      <section className="py-5" style={{ background: "#e9e9e9" }}>
        <Container>
          <Title title="News" />
          <hr />
          <div className="align-items-center content-box my-5">
            <div className="pb-2 pb-md-0">
              {data.news.edges.map(({ node }) => {
                return (
                  <SingleNews
                    key={node.id}
                    date={node.date}
                    title={node.title}
                    content={node.content.content}
                    image={node.image}
                    width={node.width}
                    height={node.height}
                    linkTitle={node.linkTitle}
                    link={node.link}
                    secondLinkTitle={node.secondLinkTitle}
                    secondLink={node.secondLink}
                  />
                )
              })}
            </div>
          </div>
        </Container>
      </section>
    </NewsWrapper>
  )
}

const NewsWrapper = styled.div`
  .date-border {
    border-bottom: 1px solid #ff8a00;
  }
  @media (min-width: 768px) {
    .date-border {
      border-right: 1px solid #ff8a00;
      border-bottom: none;
    }
  }
`

export default News
